import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import SearchIcon from "@material-ui/icons/Search";
import React, { Component } from "react";

const styles = theme => ({
  root: {
    flexGrow: 1,

    zIndex: 1,

    position: "relative",
    display: "flex"
  },

  grow: {
    flexGrow: 1
  },

  appBar: {
    background: "#fff"
  },

  search: {
    flexGrow: 2,
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f7f7f7",
    "&:hover": {
      backgroundColor: "#ebebeb"
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 400
    }
  },
  toolbar: theme.mixins.toolbar
});

class App extends Component {
  state = {
    expanded: "panel1"
  };

  handleClick = panel => (event, expanded) => {
    this.setState({
      expanded: panel
    });
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    return (
      <>
        <div className={classes.root}>
          <AppBar className={classes.appBar} color="default" position="static">
            <Toolbar>
              <img
                style={{
                  height: "126px",
                  width: "126px",
                  position: "absolute",
                  marginTop: "20px",
                  marginLeft: "20px",
                  zIndex: 999
                }}
                src="./logo.svg"
              />
              <Typography
                className={classes.title}
                variant="h6"
                style={{ marginLeft: "160px" }}
                color="inherit"
                noWrap
              >
                Nabestaanden zoekportaal
              </Typography>

              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Zoek op naam of relatienummer..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                />
              </div>
              <div className={classes.grow} />
            </Toolbar>
          </AppBar>
        </div>
        <div style={{ marginTop: "28px" }} className="wrapper">
          <Grid container spacing={24} direction="row" alignItems="stretch">
            <Grid item xs={12} sm={6}>
              <Card style={{ height: "100%" }} className={classes.paper}>
                <div className="layout flex horizontal">
                  <div className="layout flex vertical">
                    <CardContent>
                      <Typography
                        variant="title"
                        gutterBottom
                        style={{ color: "#e53238" }}
                      >
                        Profiel
                      </Typography>

                      <Typography variant="h5">E Booi</Typography>
                      <Typography variant="subheading" color="textSecondary">
                        Opeinde
                      </Typography>
                    </CardContent>

                    <List component="nav">
                      <ListItem>
                        <ListItemText
                          primary="0057284616"
                          secondary="Relatienummer"
                        />
                      </ListItem>
                    </List>

                    <CardContent>
                      <Typography color="textSecondary" variant="overline">
                        Status:
                      </Typography>
                      <Typography gutterBottom variant="title">
                        Overleden
                      </Typography>
                      <Typography color="textSecondary" variant="subheading">
                        Overlijdensdatum: 11 april 2010
                      </Typography>
                    </CardContent>
                  </div>
                  <div>
                    <CardContent className="layout flex vertical center">
                      <img
                        style={{ height: "300px" }}
                        src="https://www.mensenlinq.nl/mensenlinqads/medium/NDC/20100414/14062379.jpg"
                      />
                      <Button
                        style={{ color: "#3d99da" }}
                        href="https://www.mensenlinq.nl/overlijdensberichten/eelze-booi-2554943"
                        className={classes.button}
                      >
                        <Icon>fullscreen</Icon> Bekijk advertentie
                      </Button>
                    </CardContent>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card style={{ height: "100%" }} className={classes.paper}>
                <CardContent style={{ background: "#94415e" }}>
                  <Typography
                    variant="title"
                    gutterBottom
                    style={{ color: "#fff", textTransform: "uppercase" }}
                  >
                    Samenvatting
                  </Typography>
                </CardContent>

                <List component="nav">
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle
                        style={{ color: "#4CAF50" }}
                        color="inherit"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <a
                          href="#brp"
                          onClick={this.handleClick("panel1")}
                          style={{
                            color: "#3d99da",
                            cursor: "pointer",
                            textDecoration: "underline"
                          }}
                        >
                          Basisregistratie personen (BRP)
                        </a>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle
                        style={{ color: "#4CAF50" }}
                        color="inherit"
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <a
                          href="#overledenenregister"
                          onClick={this.handleClick("panel2")}
                          style={{
                            color: "#3d99da",
                            cursor: "pointer",
                            textDecoration: "underline"
                          }}
                        >
                          Nationaal Overledenenregister
                        </a>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle
                        style={{ color: "#4CAF50" }}
                        color="inherit"
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <a
                          href="#overlijdensadvertentie"
                          style={{
                            color: "#3d99da",
                            cursor: "pointer",
                            textDecoration: "underline"
                          }}
                        >
                          Overlijdensadvertentie beschikbaar met nabestaande(n)
                          en adres
                        </a>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle
                        style={{ color: "#4CAF50" }}
                        color="inherit"
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <a
                          href="#nabestaande"
                          style={{
                            color: "#3d99da",
                            cursor: "pointer",
                            textDecoration: "underline"
                          }}
                        >
                          Nabestaande(n) gevonden met telefoonnummer(s)
                        </a>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle
                        style={{ color: "#4CAF50" }}
                        color="inherit"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <span>
                          <a
                            href="https://www.mensenlinq.nl/overlijdensberichten/eelze-booi-2554943"
                            style={{
                              color: "#3d99da",
                              cursor: "pointer",
                              textDecoration: "underline"
                            }}
                          >
                            Mensenlinq overlijdensadvertentie gevonden
                          </a>
                        </span>
                      }
                    />
                    <ListItemIcon>
                      <Icon>open_in_new</Icon>
                    </ListItemIcon>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle
                        style={{ color: "#4CAF50" }}
                        color="inherit"
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <span>
                          <a
                            href="https://www.graftombe.nl/names/info/1835285"
                            style={{
                              color: "#3d99da",
                              cursor: "pointer",
                              textDecoration: "underline"
                            }}
                          >
                            Graftombe profiel gevonden
                          </a>
                        </span>
                      }
                    />
                    <ListItemIcon>
                      <Icon>open_in_new</Icon>
                    </ListItemIcon>
                  </ListItem>
                </List>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Card style={{ height: "100%" }} className={classes.paper}>
                <CardContent>
                  <Typography
                    variant="title"
                    gutterBottom
                    id="nabestaande"
                    style={{ color: "#e53238" }}
                  >
                    Nabestaande gegevens
                  </Typography>

                  <div className="layout flex horizontal justified center">
                    <div className="layout flex vertical">
                      <Typography variant="h6" gutterBottom>
                        Booi J
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        gutterBottom
                      >
                        Tuskenwegen 8, 9218RB, Opeinde
                      </Typography>
                    </div>

                    <Typography
                      style={{ color: "#3d99da" }}
                      variant="h6"
                      gutterBottom
                    >
                      <span className="layout flex horizontal center">
                        <Icon>phone</Icon>{" "}
                        <a
                          href="https://www.detelefoongids.nl/booi-j/wp5459793/9-1/?sn=WP5459793"
                          style={{
                            lineHeight: "24px",
                            textDecoration: "underline"
                          }}
                        >
                          0512-371378
                        </a>
                      </span>
                    </Typography>

                    <Typography
                      style={{ marginLeft: "20px" }}
                      variant="h6"
                      gutterBottom
                    >
                      <span
                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        className="layout flex horizontal center"
                      >
                        <Icon>open_in_new</Icon>
                      </span>
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Card style={{ height: "100%" }} className={classes.paper}>
                <CardContent>
                  <Typography
                    variant="title"
                    gutterBottom
                    style={{ color: "#e53238" }}
                  >
                    Gevonden data
                  </Typography>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell style={{ color: "#e53238" }}>
                          Ardanta relatiegegevens
                        </TableCell>
                        <TableCell style={{ color: "#e53238" }}>
                          Gevonden profiel
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Volledige naam
                        </TableCell>
                        <TableCell>E Booi</TableCell>
                        <TableCell>Eelze Booi</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Geboortedatum
                        </TableCell>
                        <TableCell>5 september 1930</TableCell>
                        <TableCell>5 september 1930</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Overlijdensdatum
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>11 april 2010</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Begraafplaats
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>Opeinde</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Geboorteplaats
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>Opeinde</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Geslacht
                        </TableCell>
                        <TableCell>Man</TableCell>
                        <TableCell>Man</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Woonplaats
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>Opeinde</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Adres tussenpersoon
                        </TableCell>
                        <TableCell>9201 GP Drachten</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Correspondentieadres
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>Tuskenwegen 8, 9218 RB Opeinde</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Laatst bekende adres
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>Tuskenwegen 8, 9218 RB Opeinde</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={8}>
              <ExpansionPanel
                expanded={expanded === "panel1"}
                onChange={this.handleChange("panel1")}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="title"
                    gutterBottom
                    id="brp"
                    style={{ color: "#e53238" }}
                  >
                    Basisregistratie personen (BRP)
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          <CheckCircle
                            style={{ color: "#4CAF50" }}
                            color="inherit"
                          />
                        </TableCell>
                        <TableCell>Geboortedatum matcht</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          <CheckCircle
                            style={{ color: "#4CAF50" }}
                            color="inherit"
                          />
                        </TableCell>
                        <TableCell>
                          Complete achternaam matcht met de achternaam van een
                          huwelijkspartner
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          <CheckCircle
                            style={{ color: "#4CAF50" }}
                            color="inherit"
                          />
                        </TableCell>
                        <TableCell>Compleet voorvoegsel matcht</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          <CheckCircle
                            style={{ color: "#4CAF50" }}
                            color="inherit"
                          />
                        </TableCell>
                        <TableCell>
                          Beginletter voornaam matcht met de voornaam van de
                          overledene
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          <CheckCircle
                            style={{ color: "#4CAF50" }}
                            color="inherit"
                          />
                        </TableCell>
                        <TableCell>Postcode gebied matcht</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel
                expanded={expanded === "panel2"}
                onChange={this.handleChange("panel2")}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="title"
                    gutterBottom
                    id="overledenenregister"
                    style={{ color: "#e53238" }}
                  >
                    Nationaal Overledenenregister
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Naam
                        </TableCell>
                        <TableCell>E. Booi</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Geslacht
                        </TableCell>
                        <TableCell>Man</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Geboortedatum
                        </TableCell>
                        <TableCell>05-09-1930</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Overlijdensdatum
                        </TableCell>
                        <TableCell>01-12-2010</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Adres
                        </TableCell>
                        <TableCell>Tuskenwegen 8, 9218 RB Opeinde</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Initialen match
                        </TableCell>
                        <TableCell>
                          <span className="layout flex horizontal center">
                            <CheckCircle
                              style={{ color: "#4CAF50", marginRight: "10px" }}
                              color="inherit"
                            />
                            ( 5/6 checks )
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Achternaam match
                        </TableCell>
                        <TableCell>
                          <span className="layout flex horizontal center">
                            <CheckCircle
                              style={{ color: "#4CAF50", marginRight: "10px" }}
                              color="inherit"
                            />
                            ( 4/4 checks )
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Regio match
                        </TableCell>
                        <TableCell>
                          <span className="layout flex horizontal center">
                            <CheckCircle
                              style={{ color: "#4CAF50", marginRight: "10px" }}
                              color="inherit"
                            />
                            ( 2/2 checks )
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel
                expanded={expanded === "panel3"}
                onChange={this.handleChange("panel3")}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="title"
                    gutterBottom
                    style={{ color: "#e53238" }}
                  >
                    Mensenlinq
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Naam
                        </TableCell>
                        <TableCell>Eelze Booi</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Overlijdensdatum
                        </TableCell>
                        <TableCell>01-12-2010</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Overlijdensplaats
                        </TableCell>
                        <TableCell>Opeinde</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Geplaatste rouwadvertenties
                        </TableCell>
                        <TableCell>
                          Leeuwarder Courant · Drachtster Courant
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Overlijdensbericht
                        </TableCell>
                        <TableCell>
                          <a href="https://www.mensenlinq.nl/overlijdensberichten/eelze-booi-2554943">
                            Overlijdensbericht Eelze Booi
                          </a>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Initialen match
                        </TableCell>
                        <TableCell>
                          <span className="layout flex horizontal center">
                            <CheckCircle
                              style={{ color: "#4CAF50", marginRight: "10px" }}
                              color="inherit"
                            />
                            ( 5/6 checks )
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Achternaam match
                        </TableCell>
                        <TableCell>
                          <span className="layout flex horizontal center">
                            <CheckCircle
                              style={{ color: "#4CAF50", marginRight: "10px" }}
                              color="inherit"
                            />
                            ( 4/4 checks )
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Regio match
                        </TableCell>
                        <TableCell>
                          <span className="layout flex horizontal center">
                            <CheckCircle
                              style={{ color: "#4CAF50", marginRight: "10px" }}
                              color="inherit"
                            />
                            ( 2/2 checks )
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Adres match
                        </TableCell>
                        <TableCell>
                          <span className="layout flex horizontal center">
                            <RemoveCircle
                              style={{ color: "#9E9E9E", marginRight: "10px" }}
                              color="inherit"
                            />
                            ( 1/4 checks )
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel
                expanded={expanded === "panel4"}
                onChange={this.handleChange("panel4")}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="title"
                    gutterBottom
                    style={{ color: "#e53238" }}
                  >
                    Graftombe
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Voornaam
                        </TableCell>
                        <TableCell>Eelze</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Achternaam
                        </TableCell>
                        <TableCell>Booi</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Begraafplaats
                        </TableCell>
                        <TableCell>
                          <a href="https://www.graftombe.nl/names/587/B">
                            Opeinde
                          </a>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Graftombe link
                        </TableCell>
                        <TableCell>
                          <a href="https://www.graftombe.nl/names/info/1835285">
                            Informatie Booi, Eelze
                          </a>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Initialen match
                        </TableCell>
                        <TableCell>
                          <span className="layout flex horizontal center">
                            <CheckCircle
                              style={{ color: "#4CAF50", marginRight: "10px" }}
                              color="inherit"
                            />
                            ( 6/6 checks )
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Achternaam match
                        </TableCell>
                        <TableCell>
                          <span className="layout flex horizontal center">
                            <CheckCircle
                              style={{ color: "#4CAF50", marginRight: "10px" }}
                              color="inherit"
                            />
                            ( 4/4 checks )
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          Regio match
                        </TableCell>
                        <TableCell>
                          <span className="layout flex horizontal center">
                            <CheckCircle
                              style={{ color: "#4CAF50", marginRight: "10px" }}
                              color="inherit"
                            />
                            ( 2/2 checks )
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Card style={{ height: "100%" }} className={classes.paper}>
                <CardContent>
                  <div className="layout flex vertical center-center">
                    <Typography
                      variant="title"
                      gutterBottom
                      id="overlijdensadvertentie"
                      style={{ color: "#e53238" }}
                    >
                      Overlijdensadvertentie
                    </Typography>
                    <img
                      style={{ height: "529px" }}
                      src="https://www.mensenlinq.nl/mensenlinqads/medium/NDC/20100414/14062379.jpg"
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(App);
