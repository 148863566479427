import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

let themeMaterial = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: `'Lato', sans-serif`
  },
  palette: {
    type: "light",
    primary: {
      main: "#e53238"
    },
    secondary: {
      main: "#faab19"
    }
  }
});

ReactDOM.render(
  <>
    <MuiThemeProvider theme={themeMaterial}>
      <CssBaseline />

      <App />
    </MuiThemeProvider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
